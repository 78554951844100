import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faExternalLink } from '@fortawesome/pro-regular-svg-icons'

function properCase(str) {
  return str
    .split('-')
    .map(s => s.slice(0, 1).toUpperCase() + s.slice(1))
    .join(' ')
}

const Sitemap = ({ data }) => {
  const heroImage = data.heroImage
  const markets = data.markets.edges
  const solutions = data.solutions.edges
  const capabilities = data.capabilities.edges
  const legal = data.legal.edges
  const services = data.services.edges
  const news = data.news.edges
  const blog = data.blog.edges

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-40"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="UNICOM Government sitemap"
          alt="UNICOM Government sitemap"
          subtitle=""
          type="sitemap"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="8">
              <MDBRow>
                <MDBCol md="6" className="mb-5">
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5"> Company </h3>
                  <p className="font-w-400 text-medium"> <Link to="/about/" className="effect-noline"> About us </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/careers/" className="effect-noline"> Careers </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/contact/" className="effect-noline"> Contact us </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/contract-vehicles/" className="effect-noline"> Contract vehicles </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/ethics-program/" className="effect-noline"> Ethics program </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/events/" className="effect-noline"> Events </Link> </p>
                  <p className="font-w-400 text-medium"> <Link to="/about/leadership/" className="effect-noline"> Leadership </Link> </p>
                  
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> UNICOM Global Divisions <Link to="/unicom-global/divisions/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link> </h3>

                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Search <Link to="/search/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link> </h3>

                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Privacy &amp; legal </h3>
                  {legal.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}> <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link> </p>
                  ))}

                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Newsroom <Link to="/news/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link> </h3>
                  {news.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}> <Link to={`/news/${node.slug}/`} className="effect-noline" > {properCase(node.title)} </Link> </p>
                  ))}
                </MDBCol>

                <MDBCol md="6" className="mb-5">
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Markets <Link to="/markets/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link> </h3>
                  {markets.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}> <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link> </p>
                  ))}

                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Services <Link to="/services/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link> </h3>
                  {services.map(({ node }, index) => ( <p className="font-w-400 text-medium" key={index}> <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link> </p>
                  ))}
                  <p className="font-w-400 text-medium"> <a href="https://shop.unicomgov.com/" className="effect-noline" target="_blank" > e-Procurement services </a><a href="https://shop.unicomgov.com/" className="linkedin" target="_blank" ><FontAwesomeIcon icon={faExternalLink} className="ml-2 fa-x" /></a></p>

                  <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-5"> Blog <Link to="/blog/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link> </h3>
                  {blog.map(({ node }, index) => (
                    <p className="font-w-400 text-medium" key={index}> <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link> </p>
                  ))}
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <MDBCol md="4" className="mb-5">
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Solutions <Link to="/solutions/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link> </h3>
              {solutions.map(({ node }, index) => (
                <p className="font-w-400 text-medium" key={index}> <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link> </p>
              ))}

              <h3 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5"> Capabilities <Link to="/solutions/capabilities/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link> </h3>
              {capabilities.map(({ node }, index) => (
                <p className="font-w-400 text-medium" key={index}> <Link to={node.fields.slug} className="effect-noline"> {properCase(node.frontmatter.title)} </Link> </p>
              ))}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default Sitemap

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="UNICOM Government - Sitemap"
      description="Use this sitemap to find the content you're looking for and then navigate your way around the UNICOM Government website."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                        
    />
  )
}

export const query = graphql`
  query SitemapQuery {   
    markets: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "markets" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    solutions:  allMarkdownRemark(filter: {frontmatter: {template: {eq: "solutions"}, title: {ne: "Capabilities"}}}, sort: {fields: frontmatter___title, order: ASC}) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    capabilities: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "capabilities" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }  
    services: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "services" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    blog: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    legal: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "legal" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    news: allContentfulNews(
      filter: {
        publishTo: { elemMatch: { url: { eq: "https://www.unicomgov.com" } } }
      }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          slug
          title
          date
        }
      }
    }
    heroImage: file(name: { eq: "sitemap-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`